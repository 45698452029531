<template>
  <div v-if="issueDates && currentIssueData">
    <h3 class="content-header-title float-left pr-1 mb-0" style="margin-right:1rem;"> 
      Issue Archive
    </h3>
    <h5 style="padding-top:.3rem;"> 
      Volume: <b>{{ $route.params.vol }}</b>
    </h5>
    <br>       
    <b-row>
      <b-col v-for="(item, index) in issueDates" :key="index" cols="4">
        <b-card> 
          <router-link :to="routerNameForItem(item)">
            {{ item.iss_date }}: Vol. {{ item.vol }}, Issue. {{ item.iss }}
          </router-link>
        </b-card>
      </b-col>
      <br>
    </b-row>
    <br>
  </div>
</template> 
 
<script>

//import pdfjs from 'pdfjs-dist'
import publicationApi from '@/dl_pubmill/apis/publication'
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'

export default {

  components: {
    BCard,
    BRow,
    BCol,
  },
 
  data () {
    return {
      issueDates: null,
      currentIssueData: null,
    }
  },
  watch: {
    $route: {
      //watch on route changes is only needed when same .vue is entered from multiple nav points 
      handler (newVal, oldVal) {
        console.log('111111 pubarchiveadmin watch route:', this.$route.name, 'newVal:', newVal.name, 'oldVal:', oldVal)
        this.refreshData()
      }
    },
    '$store.state.pubmillGlobalStore.currentPublicationObject.issueData': {
      handler() {
        console.log('11111 pubarchiveadmin watch handler on issueData')
        this.currentIssueData =  this.$store.state.pubmillGlobalStore.currentPublicationObject.issueData
      },
    }, 
  },
  mounted () {
    this.currentIssueData =  this.$store.state.pubmillGlobalStore.currentPublicationObject.issueData
    console.log('111111 pubarchiveadmin mounted currentIssueData:', this.currentIssueData)
    this.refreshData()
  },
  methods: {
    routerNameForItem (item) {
      const params = {
        issn: this.$route.params.issn,
        vol: item.vol,
        issue: item.iss
      }
      const returnDict = {
        name: 'publication-issue-admin', 
        params
      }
      if (item.vol === this.currentIssueData.vol && item.iss === this.currentIssueData.iss) {
        returnDict.name = 'current-issue-admin'
      } 
      return returnDict
    },

    getIssueDates () { 
      const vol = this.$route.params.vol
      publicationApi.getIssueDates(this.$route.params.issn, vol, this, null)
    },

    backFromGetIssueDates (serverData) {

      if (serverData.error) {
        console.log(serverData.error)
      } else {
        console.log('backFromGetIssueDates', serverData, 'currentIssueData', this.currentIssueData)
        this.issueDates = serverData   
      } 
    },
    refreshData () {
      this.issueDates = []
      this.getIssueDates()
    }
  },
}
</script>
